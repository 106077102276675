import React from 'react'
import Brandmarketing1 from '../components/brandmarketing';
import FAQ1 from '../components/faqs';


function Brandmarketingpage1() {
  return (
    <div>
        <Brandmarketing1/>
        <FAQ1/>

    </div>
  )
}

export default Brandmarketingpage1;