import React from 'react'
import "./seoreward.css"

function Seoreward() {
    return (
        <div className='reward-body-seo'>
             
            <img alt='digital' src='../images/awards.jpg' className='image-awards-seo' />
        </div>
    )
}

export default Seoreward;