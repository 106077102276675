import React from 'react'
import Webdevelopment1 from '../components/webdevelopment';
import Faqwebdevelopment1 from '../components/faqwebdevelopment';
 


function Webdevelopmentpage1() {
  return (
    <div>
         <Webdevelopment1/>
         <Faqwebdevelopment1/>

    </div>
  )
}

export default Webdevelopmentpage1;