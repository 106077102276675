import React from 'react'
import Digitalmarketing1 from '../components/digitalmarketing';
import Faqdigitalmarketing1 from '../components/faqdigitalmarketing';



function Brandmarketingpage1() {
  return (
    <div>
       <Digitalmarketing1/>
       <Faqdigitalmarketing1/>
    </div>
  )
}

export default Brandmarketingpage1;