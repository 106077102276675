import React  from 'react';
import AppRoutes from "../src/routes"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form1 from './components/herosection/form';
 
 

const App = () => {

  return (
    <div className="App">
      <AppRoutes />
     
    </div>

     
  );
};

export default App;






