import React from 'react'
import Ecommerce1 from '../components/ecommerce';
import Faqecommerce1 from '../components/faqecommerce';
 


function Ecommercepage1() {
  return (
    <div>
        <Ecommerce1/>
        <Faqecommerce1/>

    </div>
  );
}

export default Ecommercepage1;