import React from 'react'
import About from '../components/about';
import Aboutorigin1 from '../components/aboutorigin';
import Leadershipteam from '../components/leadership';

function Aboutpage1() {
  return (
    <div>
       <About/>
       <Aboutorigin1/>
       <Leadershipteam/>

    </div>
  )
}

export default Aboutpage1;