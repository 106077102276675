import React from 'react'
import Seo1 from '../components/seo';
import FAQseo from '../components/faqseo';
 
 


function Seopage1() {
  return (
    <div>
        <Seo1/>
        <FAQseo/>
         

    </div>
  )
}

export default Seopage1;